<template>
  <div class="flex justify-end my-2">
    <div class="text-white">
      <button @click="updateCreate(true)" class="px-2 py-2 text-xs bg-gray-900">
        {{ newTitle || '---' }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'action-button',
  props: ['breadcrumbs'],
  data() {
    return {}
  },
  methods: {},
  computed: {},
}
</script>

<style></style>
