<template>
  <div class="flex flex-col w-full relative p-2">
    <h1 class="w-full font-bold">{{ data.caption }}</h1>
    <div class="flex flex-col">
      <h1 class="text-base border-b w-1/2" v-if="data.title">
        {{ data.title }}
      </h1>

      <template v-if="data.cards.length">
        <div class="flex flex-wrap text-gray-500">
          <div
            v-for="(card, index) in data.cards"
            :class="data.card_width[index]"
            class="px-1 my-2"
            :key="`table-${index}`"
          >
            <div
              v-if="card.show && (data.profile.picture || username)"
              class="py-10"
            >
              <img
                v-if="data.profile.picture"
                class="h-36 left-0 relative object-scale-down"
                :src="data.profile.picture"
              />
              <avatar v-else :username="username" :size="150" />
            </div>

            <h1
              v-if="card.title"
              class="text-sm font-semibold text-gray-800 mb-2"
            >
              {{ card.title }}
            </h1>
            <div v-if="card.component">
              <component
                :is="card.component"
                :options="card.options"
                :series="card.series"
                :height="card.options.chart.height"
              />
            </div>
            <table
              v-else-if="card.cols && card.cols.length"
              class="table-auto text-left w-full"
            >
              <tbody>
                <tr
                  v-for="(row, row_index) in card.cols"
                  :key="`row-${row_index}`"
                >
                  <th
                    :key="`row-${row_index}-label`"
                    class="py-1"
                    :class="data.col_width[row_index]"
                  >
                    {{ row.label }}
                  </th>
                  <td
                    :key="`row-${row_index}-data`"
                    class="py-1"
                    :class="data.col_width[row_index + 1]"
                  >
                    {{ row.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="data && data.neutral" class="flex flex-col my-4 px-2">
            <div class="w-full font-semibold">
              {{ data.neutral.label }}
            </div>
            <div class="w-full">
              {{ data.neutral.value }}
            </div>
          </div>
        </div>
      </template>

      <div v-else class="w-full m-auto">
        <h1>No data to show.</h1>
      </div>

      <div v-if="data && data.footer" class="mt-16">
        <component :is="data.footer.component" v-bind="data.footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card-table',
  props: ['data'],
  components: {},
  data() {
    return {}
  },
  computed: {
    username() {
      return this.data.profile.initial
        ? this.data.profile.initial
        : `${this.data.profile.first_name} ${this.data.profile.last_name}`
    },
  },
}
</script>
