<template>
  <div class="flex flex-col w-full">
    <ActionButton />
    <data-repeater :title="data.title" :rows="data.rows" :columns="data.columns" :show="false" />
  </div>
</template>

<script>
import ActionButton from '@/components/Header/ActionButton'

export default {
  name: 'card-grid',
  props: ['data'],
  components: { ActionButton },
  data() {
    return {}
  },
}
</script>

<style></style>
