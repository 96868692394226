<template>
  <div class="flex flex-col w-full h-full">
    <Tabs v-model="current" :tabs="captions" :show="show" />
    <div
      v-if="data && data.tabs"
      class="w-full p-2 border border-gray-200 -mt-px h-full"
    >
      <div
        v-if="tabMessage"
        class="items-center text-sm flex justify-between bg-red-500 -m-2 text-white px-2 py-1 mb-1 font-bold"
      >
        <p>{{ tabMessage.left }}</p>
        <a
          href="#"
          @click.prevent="$emit('unarchive', !roleprofile.archived)"
          class="border border-white rounded px-2 py-1 hover:bg-red-600"
          >{{ tabMessage.right }}</a
        >
      </div>
      <component
        :is="data.tabs[current].component"
        v-bind="componentProps"
        :permission="componentProps.permission || permission"
      />
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/TabCard/Tabs'
import CardTable from '@/components/CardTable/CardTable'
import CardGrid from '@/components/CardGrid/CardGrid'
import DataRepeater from '@/components/DataTable/DataRepeater'

export default {
  name: 'tab-card',
  props: ['value', 'data', 'roleprofile', 'show'],
  components: {
    Tabs,
    CardTable,
    CardGrid,
    DataRepeater,
  },
  data() {
    return {
      current: 0,
      attributes: {},
      permission: {
        module: '',
        subModule: '',
        childModule: '',
        operation: '',
        options: [],
      },
    }
  },
  mounted() {},
  computed: {
    captions() {
      return this.data && this.data.tabs
        ? this.data.tabs.map(tab => tab.caption)
        : []
    },
    componentProps() {
      return {
        data: this.data.tabs[this.current].data,
        rows: this.data.tabs[this.current].rows,
        columns: this.data.tabs[this.current].columns,
        addBtn: this.data.tabs[this.current].addBtn,
        icon: this.data.tabs[this.current].icon,
        resource: this.data.tabs[this.current].resource,
        callback: this.data.tabs[this.current].callback,
        actions: this.data.tabs[this.current].actions,
        label: this.data.tabs[this.current].label,
        permission: this.data.tabs[this.current].permission,
      }
    },
    tabMessage() {
      return this.data.tabs && this.roleprofile && this.roleprofile.archived
        ? {
            left:
              'Dieses Rollenprofil ist deaktiviert. Bitte aktivieren Sie das Rollenprofil um es zu bearbeiten',
            right: 'Rollenprofil aktivieren',
          }
        : null
    },
  },
  watch: {
    value: function(n) {
      if (n) {
        this.current = 0
        this.$forceUpdate()
        this.$emit('input', false)
      }
    },
  },
}
</script>

<style></style>
